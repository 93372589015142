@mixin block-base-sm() {
	background: white;
	padding: $padding-rythm-sm;
}

@mixin block-base() {
	background: white;
	padding: $padding-rythm;
}

@mixin base-heading() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1;
	font-family: $font;
	margin: 0;
}

@mixin base-link() {
	transition: border-color 150ms ease-in;
	color: inherit;
	display: inline-block;
	position: relative;
	border-bottom: 1px solid $dark-grey;
	&:hover,
	&:focus,
	&:active {
		border-bottom-color: $middle-grey;
	}
}
