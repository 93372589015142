.icon {
	border: 1px solid $red;
	border-radius: 2px;
	width: 100%;
	margin: 0 $padding-rythm-sm/2 $padding-rythm-sm 0;
	display: flex;
	box-shadow: $shadow;

	&--img {
		display: flex;
		background-color: $red;
		padding: 0 1px 1px 0;
		height: 34px;
		min-width: 34px;
		flex: 0 1 auto;
		width: 100%;
		color: white;
		transition: color 0.2s ease, background-color 0.2s ease;

		& > svg {
			margin: auto;
			max-width: 20px;
			max-height: 20px;
			align-self: center;
		}

		&:hover,
		&:focus,
		&:active {
			color: $red;
			background-color: white;
			transition: color 0.2s ease, background-color 0.2s ease;
		}
	}

	&--text {
		color: $red;
		padding: 0 $padding-rythm-sm/2;
		font-weight: 500;
		font-family: $font;
		justify-content: center;
		align-self: center;
		display: none;
	}

	&:last-child {
		margin-right: 0;
	}

	&__blue {
		border-color: $blue;

		& > span {
			background-color: $blue;
			transition: color 0.2s ease, background-color 0.2s ease;

			&:hover,
			&:focus,
			&:active {
				background-color: white;
				color: $blue;
				transition: color 0.2s ease, background-color 0.2s ease;
			}
		}
	}

	&__staff-invert {
		border: none;
		margin: 0 5px 0 0;
		display: inline-block;
		width: auto;
		min-width: $padding-rythm-sm*2;
		& > span {
			border-radius: 2px;
			background-color: white;
			max-width: 25px;
			width: auto;
			color: $red;
			transition: color 0.2s ease, background-color 0.2s ease;

			&:hover,
			&:focus,
			&:active {
				background-color: $red;
				color: white;
				transition: color 0.2s ease, background-color 0.2s ease;
			}
		}
	}
}

@include from($bp-medium) {
	.icon {
		flex-flow: row nowrap;
		width: auto;
		color: $red;
		transition: color 0.2s ease, background-color 0.2s ease;

		&:hover,
		&:focus,
		&:active {
			background-color: $red;
			color: white;
		}

		& + & {
			margin-left: $padding-rythm-sm;
		}

		&--text {
			color: inherit;
			padding: 0 $padding-rythm-sm/2;
			font-weight: 500;
			font-family: $font;
			justify-content: center;
			align-self: center;
			display: inline;

			&__print-only {
				display: none;
			}
		}

		&--img {
			width: 34px;

			&:hover,
			&:focus,
			&:active {
				background-color: $red;
				color: white;
			}

			&__full {
				width: 100%;

				&:hover,
				&:focus,
				&:active {
					background-color: white;
					color: $red;
				}
			}
		}

		&:last-child {
			margin-right: 0;
		}

		&__staff-invert {
			display: none;
		}
	}
}

@media print {
	.icon {
		&--img {
			width: 34px !important;
			display: flex !important;
			background-color: white !important;
			padding: 0 1px 1px 0 !important;
			height: 34px !important;
			min-width: 34px !important;
			flex: 0 1 auto;
			width: 100%;
			color: #e53641 !important;
		}
		&--text {
			&__print-only {
				display: inline-block;
			}
		}
		&__staff-invert {
			display: none;
		}
	}
}
