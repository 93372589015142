.staff-listing {
	margin-top: $padding-rythm-sm;
	display: flex;
	flex-wrap: wrap;
	line-height: 1.2;
	&:first-of-type {
		margin-top: $padding-rythm-sm*2;
	}
	& > * {
		margin: 5px 0;
	}
	&--name {
		font-size: $fs-body;
		font-weight: bold;
		display: inline-block;
		flex-grow: 1;
		font-family: $condensed-font;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		letter-spacing: 0.05em;
		width: 100%;
		order: 1;
		& > span {
			display: inline;
		}
	}

	&--phone {
		line-height: 1;
		display: flex;
		align-items: center;
		width: 100%;
		order: 3;
	}
	&--email,
	&--position {
		flex-basis: 100%;
		font-size: $fs-body;
		font-weight: 400;
		width: auto;
		align-items: center;
	}
	&--position {
		font-style: italic;
		order: 2;
	}
	&--email {
		font-style: italic;
		order: 4;
		display: flex;
		flex-flow: row nowrap;
	}
}

@include from($bp-medium) {
	.staff-listing {
		&--name {
			font-size: $fs-body;
			width: auto;
		}
		&--phone {
			text-align: right;
			font-weight: bold;
			width: auto;
			order: 2;
		}
		&--position {
			order: 3;
		}
		&--email {
			display: block;
		}
	}
}

@media print {
	.staff-listing {
		&:first-of-type {
			margin-top: 0;
		}
		> * {
			font-size: 12px !important;
		}
	}
}
