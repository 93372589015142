.l-content {
	padding: $padding-rythm-sm;

	&--container {
		box-shadow: $shadow;
		padding: $padding-rythm-sm;
	}

	&--title {
		color: $blue;
		margin: $padding-rythm-sm 0;
		font-family: $condensed-font;

		&__red {
			color: $red;
			margin-top: $padding-rythm-sm;
		}
	}

	&--member-cards {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: $padding-rythm-sm;
	}

	&--similar {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: $padding-rythm-sm;
	}
}

@include from($bp-small) {
	.l-content {
		&--member-cards {
			grid-template-columns: 1fr 1fr;
		}
		&--similar {
			grid-template-columns: 1fr 1fr;
		}
	}
}

@include from($bp-medium) {
	.l-content {
		padding: 0 $padding-rythm;

		&--content {
			column-count: 2;
			column-width: auto;
			column-gap: $padding-rythm;
		}

		&--title {
			margin: $padding-rythm 0 $padding-rythm/2 0;

			&__red {
				margin: 0 0 $padding-rythm-sm*2 0;
			}
		}
	}
}

@include from($bp-large) {
	.l-content {
		&--member-cards {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}

@media print {
	.l-content {
		margin-top: 30px;
	}
}
