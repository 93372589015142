.l-site-search {
	margin: $padding-rythm-sm 0;
	background-color: none;
	color: $dark-grey;

	&--controls {
		border-bottom: 1px solid $dark-grey;
		min-height: 20px;

		&__invert {
			border-color: $middle-grey;
			color: white;
			background-color: $dark-grey;
		}

		& > input[type="search"] {
			border: none;
			background: none;
			width: 75%;
			display: inline-block;
			padding: 10px;
		}
	}

	&--icon {
		display: inline-block;
		margin-right: 5px;
		background: none;
		appearance: none;
		border: 0;

		& > svg {
			margin-bottom: -3px;
			color: inherit;
		}
	}

	&--update {
		margin: $padding-rythm-sm 0;
		color: white;
		background-color: $blue;
		font-family: $condensed-font;
		border-radius: 2px;
		border: none;
		width: 100%;
		padding: $padding-rythm-sm/2;
		font-size: $fs-body;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		letter-spacing: 0.01em;
	}

	&--note {
		padding: $padding-rythm-sm/2 0;
		background: none;
		color: $middle-grey;
		font-size: $fs-body-sm;
	}
}

@include from($bp-medium) {
	.l-site-search {
		background-color: $dark-grey;
		color: white;

		&--controls {
			border-color: white;
			& > input[type="search"] {
				width: 70%;
				color: white;
			}
		}

		&--icon {
			& > svg {
				color: white;
			}
		}

		&--update {
			display: none;
		}

		&--note {
			color: $middle-grey;
		}
	}
}
