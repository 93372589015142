.contact-card {
	box-shadow: $shadow;
	padding: $padding-rythm-sm;
	border-radius: 2px;
	list-style-type: none;

	&--person-details {
		background: $blue;
		margin-bottom: $padding-rythm-sm;
		border-radius: 2px;
		padding: $padding-rythm-sm;
		color: white;
	}

	&--title {
		color: $red;
		display: inline-block;
		margin-right: 5px;
		font-family: $condensed-font;
		font-weight: 800;
		font-size: $fs-h2-sm;
		width: 100%;
		position: relative;

		&-role {
			font-size: $fs-body;
			font-family: $font;
			font-weight: 500;
			color: $red;
			margin: 0 5px;
		}

		& > .icon {
			position: absolute;
			right: 0;
			top: 0;
			max-width: 36px;
		}
	}

	&--house {
		color: $blue;
		margin: $padding-rythm-sm 0 $padding-rythm-sm * 2 0;
	}

	&--icons {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-flow: row nowrap;
	}

	&--partner {
		&-label {
			font-size: $fs-body;
			color: inherit;
			font-family: $condensed-font;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			letter-spacing: 0.01em;
		}
		&-value {
			font-size: $fs-body;
		}

		+ .contact-card--positions {
			margin-top: 20px;
		}
	}

	&--positions {
		& > li {
			font-style: italic;
			font-size: $fs-body-sm;
			+ li {
				margin-top: $padding-rythm-sm/2;
			}
		}
	}

	&--grid {
		display: none;
		grid-template-columns: 1fr;
	}

	&--column {
		display: flex;
		flex-direction: column;
	}

	&--office {
		&-title {
			display: none;
		}
		&-edit {
			position: absolute;
			right: 7px;
			top: 10px;

			& > svg {
				display: block;
				width: 20px;
				height: 20px;
			}
		}
		&-details {
			background-color: $blue;
			position: relative;
			width: 100%;
			color: white;
			border-radius: 0 0 2px 2px;
			overflow: hidden;
			padding: $padding-rythm-sm/2;

			opacity: 0;
			max-height: 0;
			pointer-events: none;
			transition: max-height 0.8s ease-out, opacity 0.8s step-end,
				margin-bottom 0.8s step-end;

			& > hr {
				margin: $padding-rythm-sm 0;
			}

			& + & {
				margin-top: $padding-rythm-sm;
			}

			&.is-visible {
				opacity: 1;
				height: auto;
				max-height: 15000px;
				pointer-events: all;
				transition: max-height 0.8s ease-in-out;
				margin-bottom: $padding-rythm-sm/2;
			}
		}
		&-address {
			margin-bottom: $padding-rythm-sm/2;
			white-space: pre-wrap;
			line-height: 1.5;
			font-style: normal;
		}
		&-contact {
			display: flex;
			flex-flow: column wrap;
			margin-top: $padding-rythm-sm * 2;
			& > dt {
				flex: 0 1 100%;
				font-weight: 900;
				font-family: $condensed-font;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				letter-spacing: 0.05em;
			}

			& > dd {
				flex: 0 1 100%;
				margin: 0;
				padding-bottom: $padding-rythm-sm/2;
			}
		}
	}

	&--save {
		display: none;
		flex-flow: row nowrap;
		width: 100%;
		justify-content: flex-start;
		align-items: stretch;
	}

	&--link {
		border: none;
		border-radius: 2px;
		background-color: $blue;
		border: 1px solid $blue;
		color: white;
		width: 100%;
		padding: 10px;
		text-align: center;
		transition: color 0.2s ease, background-color 0.2s ease;

		& > svg {
			transform: rotate(90deg);
			float: right;
			margin-top: 4px;
			height: 10px;
		}

		&:hover,
		&:focus,
		&:active {
			background-color: white;
			color: $blue;
			transition: color 0.2s ease, background-color 0.2s ease;
		}
	}

	& + & {
		margin-top: $padding-rythm-sm;
	}

	&__small {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		& + & {
			margin-top: 0;
		}
	}
}

@include from($bp-medium) {
	.contact-card {
		position: relative;
		&--icons {
			justify-content: flex-start;
			margin: 0;
		}

		&--person-details {
			margin-bottom: $padding-rythm-sm;
		}

		&--title {
			font-size: $fs-h2;
			&__small {
				font-size: $fs-h3;
			}
		}

		&--partner {
			&-label,
			&-value {
				font-size: $fs-body;
			}
		}

		&--positions {
			& > li {
				font-size: $fs-body-sm;
				font-weight: bold;
			}
		}

		&--grid {
			grid-template-columns: 1fr 1fr;
			grid-gap: $padding-rythm-sm;
		}

		&--office {
			&-title {
				font-size: $fs-h2;
				display: inline-block;
				text-transform: uppercase;
				font-weight: 800;
				font-family: $condensed-font;
			}

			&-edit {
				& > svg {
					width: 16px;
					height: 16px;
				}
			}

			&-details {
				opacity: 1;
				height: auto;
				pointer-events: all;
				margin-bottom: $padding-rythm-sm;
				max-height: 100%;
				border-radius: 2px;
				padding: $padding-rythm-sm;
			}

			&-address {
				margin: $padding-rythm-sm/2 0;
			}

			&-contact {
				flex-flow: row wrap;
				& > dt {
					flex: 0 1 32%;
				}
				& > dd {
					flex: 0 1 68%;
				}
			}
		}
		&--save {
			justify-content: space-between;
			display: flex;

			& > a {
				width: 100%;
			}
		}

		&--link {
			& > svg {
				float: none;
				margin-top: 0;
			}
		}
	}
}

@media print {
	.contact-card {
		&--grid {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: $padding-rythm-sm;
			max-width: 100% !important;
		}

		&--person-details {
			background: none;
		}
		&--link {
			display: none;
		}
		&--column {
			columns: 2 50%;
		}

		&--office {
			border: 1px solid lightgrey;
			margin: 10px 0;
			page-break-inside: avoid !important;
			&-details {
				opacity: 1;
				height: auto;
				max-height: 100%;
				background-color: white !important;
				& > hr {
					color: lightgrey;
				}
			}
			&-title {
				color: black !important;
				min-width: 100% !important;
				font-size: $fs-h2-sm;
				display: inline-block;
				text-transform: uppercase;
				font-weight: 800;
				font-family: $condensed-font;
				margin-bottom: 10px;
				h3 {
					font-size: $fs-h2;
				}
			}
			&-staff {
				columns: 3;
			}
		}
	}
}
