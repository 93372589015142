/**
 * Media Queries - *Try* and make everything fit within these - use @from/to/between
 */
$bp-small: 768;
$bp-medium: 1024;
$bp-large: 1280;
$bp-very-large: 1400;

/**
 * Font definitions
 */
$defaultFontSize: 1;

@font-face {
	font-family: "Gotham Light";
	src: url("../fonts/Gotham-Light.woff") format("woff");
}
@font-face {
	font-family: "Gotham Medium";
	src: url("../fonts/Gotham-Medium.woff") format("woff");
}
@font-face {
	font-family: "Helvetica Black";
	src: url("../fonts/HELVETICANEUELTSTD-XBLKCN.woff") format("woff");
}

$light-font: "Gotham Light", Arial, sans-serif;
$font: Arial, sans-serif;
$condensed-font: "Helvetica Black", Arial, sans-serif;

/**
 * Font Sizes
 */
$fs-h1: 36px;
$fs-h1-sm: 28px;

$fs-h2: 25px;
$fs-h2-sm: 20px;

$fs-h3: 20px;
$fs-h3-sm: 14px;

$fs-body: 14px;
$fs-body-sm: 12px;

/**
 * Transitions
 */
$base-anim-rythm: 300ms;
$transition-default: all $base-anim-rythm ease-in-out;

/**
 * Padding
 * Usage: padding: $padding-rythm*2
 */
$padding-rythm: 50px;
$padding-rythm-sm: 20px;

/**
 * Raw Colors
 */
$dark-grey: #333132;
$middle-grey: #9b9b9b; // #a7a9ac - put the similar colors you've replaced here
$light-grey: #efeff0; // #eaebec
$red: #e53641;
$blue: #466179;

/**
 * z-index stack - where possible use DOM order or small hoists (eg z-index: 1 vs z-index: $zi-layer-4)
 */
$zi-layer-1: 20;
$zi-layer-2: 40;
$zi-layer-3: 60;
$zi-layer-4: 80;
$zi-layer-nav: 200;

/**
 * Misc
 */
$shadow: 0 2px 8px 0 rgba(51, 49, 50, 0.2);
$max-width: ($bp-medium * 1px);
