.l-sidebar {
  color: white;
  width: 100%;
  position: fixed;
  z-index: $zi-layer-1;
  background-color: $dark-grey;

  &--section {
    margin: $padding-rythm-sm/2 0;

    &__hide-mobile {
      display: none;
    }
  }

  &--heading {
    font-family: $condensed-font;
    text-transform: uppercase;
    display: none;
    line-height: 1.1;
    &-mobile {
      font-family: $condensed-font;
      text-transform: uppercase;
      word-wrap: none;
      display: inline-block;
      padding: $padding-rythm-sm/2;
      width: 100%;
      text-align: center;
      font-size: $fs-h1-sm;
    }
  }

  &--navigation {
    background-color: white;
    color: $dark-grey;

    &.is-visible {
      padding: $padding-rythm $padding-rythm-sm;
    }
  }

  &--content {
    & > h1 {
      font-family: $condensed-font;
      text-transform: uppercase;
    }
  }

  &--dropdown {
    background-color: $dark-grey;
    color: $middle-grey;
    margin: 0;
    padding: $padding-rythm-sm/2;
    border-radius: 0 0 2px 2px;

    & > li {
      margin: 10px 0;
      &.is-active,
      &:active,
      &:focus,
      &:hover {
        color: white;
      }
    }
  }

  &--link {
    &:active,
    &:focus,
    &:hover {
      color: white;
    }
  }

  &--icon {
    &.is-active {
    }
  }
}

@include to(325) {
  .l-sidebar {
    &--heading {
      &-mobile {
        font-size: $fs-h2;
      }
    }
  }
}

@include from($bp-medium) {
  .l-sidebar {
    height: 100vh;
    width: 400px;
    overflow-y: auto;

    &--heading {
      display: inline-block;
      margin-bottom: $padding-rythm;
      font-size: $fs-h1;
      &-mobile {
        display: none;
      }
    }

    &--section {
      &__hide-mobile {
        display: block;
      }
    }

    &--navigation {
      background-color: $dark-grey;
      color: white;
    }

    &--search {
      margin-top: $padding-rythm;
    }

    &--content {
      padding: $padding-rythm;

      & > h1 {
        font-family: $condensed-font;
        text-transform: uppercase;
      }
    }

    &--dropdown {
      padding: 0;
      margin: 0 $padding-rythm-sm $padding-rythm-sm $padding-rythm;

      & > li {
        margin: 5px 0;
        color: $middle-grey;
      }
    }
  }
}
