.contact-list {
	padding: $padding-rythm 0 $padding-rythm $padding-rythm-sm;
	width: 95%;
}

@include from($bp-small) {
	.contact-list {
		padding: $padding-rythm;
		margin: 0;
	}
}

@include from($bp-medium) {
	.contact-list {
		padding: 0 $padding-rythm $padding-rythm $padding-rythm;
	}
}
