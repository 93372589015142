.l-error-page {
	width: 100%;

	height: 100vh;
  background-color: $dark-grey;

	&--message {
		background-color: white;
		padding: $padding-rythm 0;
		margin: 0 $padding-rythm;
		text-align: center;
	}
}
