.l-directory-utilities {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 1;

  &--confidential {
    flex-grow: 2;
    padding: 9px $padding-rythm-sm/2;
    color: white;
    background-color: $red;
    text-transform: uppercase;
    text-align: center;
    font-family: $condensed-font;
    font-size: 12pt;
    height: 100%;
    margin-right: 0;
    box-shadow: $shadow;
    z-index: $zi-layer-3;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.01em;
  }

  &--icons {
    display: none;
    height: 56px;
  }
}

@include from($bp-medium) {
  .l-directory-utilities {
    position: relative;
    width: auto;
    padding: $padding-rythm-sm * 2 $padding-rythm;

    &--confidential {
      padding: 9px $padding-rythm-sm/2;
      margin-right: $padding-rythm;
      font-size: 22pt;
    }

    &--icons {
      display: flex;
      flex-flow: row nowrap;
      align-content: space-around;
      &-link {
        width: 34px;
        height: 34px;
      }
    }
  }
}
