.captioned-media {
	&--asset {
		width: 100%;
		height: auto;
		display: block;
	}
	&--caption {
		font-size: 14px;
		padding-bottom: $padding-rythm-sm;
		border-bottom: 1px solid black;
	}
}
