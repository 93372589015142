.l-directory {
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	margin-top: 30px;
}

@include from($bp-small) {
	.l-directory {
		display: inline-block;
		width: 100%;
	}
}
