$input-text-active-offset: 8px;
$input-padding: $padding-rythm-sm / 2;
$input-main-color: grey;

.form-textarea {
  margin-bottom: $padding-rythm;
  max-width: 100%;
  width: 100%;
  padding: $padding-rythm 0;
}

.form-input {
  position: relative;
  width: 100%;
  color: $input-main-color;

  &--field,
  &--label {
    display: block;
    line-height: 1.3;
    font-weight: 400;
    background: none;
  }
  &--field {
    border: 0 none;
    background-color: white;
    color: $dark-grey;
    padding: $padding-rythm-sm/2;
    width: 100%;
    appearance: none;
    border-radius: 0 0 2px 2px;

    &:focus,
    &[value]:not([value=""]),
    &.is-focused {
      + .form-input--label {
        padding: 6px 0 0 0;

        font-size: 11px;
      }

      &.is-valid,
      &.is-valid + .form-input--label {
      }

      &.is-invalid,
      &.is-invalid + .form-input--label {
        border-color: red;
      }
    }

    &.is-valid,
    &.is-valid + .form-input--label {
    }

    &.is-invalid,
    &.is-invalid + .form-input--label {
      border-color: red;
    }
    &.is-invalid {
      ~ .form-input--error-messages {
        display: block;
      }
    }
    &:invalid {
      // Overide browser defaults. We use classes as below (for browser compat and UX reasons)
      outline: 0 none;
      box-shadow: none;
    }
    &:required {
      + .form-input--label:after {
        content: "*";
        display: inline;

        color: red;
      }
    }

    &[type="checkbox"],
    &[type="radio"] {
      width: auto;
    }
  }
  &--label {
    position: absolute;
    left: $input-padding;
    top: 0;
    padding: 5px 0;
    align-items: center;
    pointer-events: none;
    font-weight: 500;

    white-space: nowrap;

    backface-visibility: hidden;

    transition: font-size 80ms linear, padding 80ms linear;

    &-icon {
      display: flex;
      position: relative;
      background-color: $red;
      color: white;
      padding: 5px;
      left: 0;
      border-radius: 2px 2px 0 0;
    }
  }

  &--icon {
    margin-right: $padding-rythm-sm / 2;
    & > svg {
      height: 20px;
    }
  }

  &--error-messages {
    width: 100%;
    &-single {
      margin-top: 12px;
    }
  }

  &--help {
    width: 100%;
    padding: 12px;
  }

  + .form-input {
    margin-top: $padding-rythm-sm / 2;
  }
}

.form-boolean {
  & > label {
    padding: 10px 10px 10px 20px;
    display: inline-block;
    font-weight: bold;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      display: inline-block;
      border: 1px solid $dark-grey;
      left: -4px;
      top: 10px;
      line-height: 0.8;
      font-size: 20px;
      transition: all 150ms ease-in-out;
    }
  }

  & > input {
    opacity: 0;
    width: 0px;
    height: 0px;

    &:checked ~ label:before {
      content: "\2713";
      background-color: $dark-grey;
      color: white;
    }
  }
}

@include from($bp-small) {
  .form-input {
    display: flex;
    &--label {
      min-width: 125px;
    }
  }
}

@include from($bp-medium) {
  .form-input {
    display: flex;
    + .form-input {
      margin-top: $padding-rythm / 2;
    }

    &--field {
      border-radius: 0 2px 2px 0;
    }

    &--label {
      border-radius: 2px 0 0 2px;
      min-width: 125px;
      padding: 8px;
    }

    &--icon {
      & > svg {
        height: 22px;
      }
    }
  }

  .form-boolean {
    & > label {
      padding-left: 30px;
      &:before {
        content: "";
        border-color: white;
      }
    }

    & > input {
      &:checked ~ label:before {
        content: "\2713";
        background-color: $dark-grey;
        color: white;
      }
    }
  }
}

@include from($bp-large) {
  .form-group {
    display: flex;
    .form-input {
      margin-bottom: 0;
    }
    &--item {
      flex: 1;
      + .form-group--item {
        margin-left: $input-padding;
      }
    }
  }
}
