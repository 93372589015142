.l-page {
	width: 100%;
	display: block;
	margin: 100px 0 $padding-rythm-sm 0;

	&--login {
		margin: 0;
	}
}

@include from($bp-medium) {
	body,
	html {
		overflow: hidden;
	}

	.l-page {
		width: calc(100% - 400px);
		height: 100vh;
		overflow-y: scroll;
		margin: 0 0 0 400px;

		&--login {
			margin: 0;
			width: 100vw;
			overflow: hidden;
		}
	}
}

@media print {
	.l-page {
		margin: 0;
		height: auto;
	}
}
