//TODO this is getting messy (lots of modifiers depending on where the accordion goes on page) - would like to re-think if time. -AS
.accordion {
	&--opener {
		background-color: $blue;
		color: white;
		outline: 0;
		border: 0;
		width: 100%;
		padding: $padding-rythm-sm/2;
		border-radius: 2px;
		border: 1px solid $blue;
		font-size: $fs-body;
		position: relative;
		transition: all 250ms ease-in-out;
		text-transform: uppercase;
		font-family: $condensed-font;
		box-shadow: $shadow;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		letter-spacing: 0.01em;

		& > svg {
			float: right;
			width: 16px;
			height: 16px;
		}

		&:hover,
		&:active,
		&:focus {
			background-color: white;
			color: $blue;
		}

		&.is-open {
			background: none;
			color: $blue;
			padding: 6px;
			box-shadow: none;

			& > svg {
				float: none;
				margin-top: 2px;
				transform: rotate(45deg);
			}
		}

		&__dark {
			background-color: $blue;
			color: white;
			padding: $padding-rythm-sm/2;
			text-align: left;
			border: none;
			transition: border-radius 0.8s step-end;

			&.is-open {
				background-color: $blue;
				color: white;
				border: none;
				border-radius: 2px 2px 0 0;
				padding: $padding-rythm-sm/2;
				transition: border-radius 0.8s step-start;
				& > svg {
					transform: none;
					float: right;
					margin-top: 0;
				}
			}
		}

		&__sidebar {
			background: $dark-grey;
			color: white;
			padding: $padding-rythm-sm/2;
			text-align: left;
			border: none;
			transition: border-radius 0.8s step-end;
			text-transform: none;
			font-family: $font;

			& > svg {
				float: left;
			}

			&.is-open {
				color: white;
				background: $dark-grey;
				border-radius: 2px 2px 0 0;
				padding: $padding-rythm-sm/2;
				border: none;
				margin-bottom: 0;

				& > svg {
					transform: none;
					margin-top: 0;
				}
			}
		}

		&__search {
			text-transform: capitalize;
			background-color: white;
			color: $blue;
			box-shadow: $shadow;

			&.is-open {
				position: absolute;
				top: 59px;
				border: none;
				background-color: white;
				color: $blue;
				padding: $padding-rythm-sm/2 0;

				.accordion--icon__search.is-active {
					& > svg {
						transform: rotate(45deg);
					}
				}
			}
		}
	}

	&--icon {
		opacity: 0;
		position: absolute;
		width: 16px;
		height: 16px;
		right: $padding-rythm-sm/2;
		top: $padding-rythm-sm/2;
		transition: opacity 0.2s ease;

		&.is-active {
			opacity: 1;
		}

		&__left {
			right: $padding-rythm-sm/2;
			top: $padding-rythm-sm/2;
			display: inline-block;
		}

		&__search {
			transform: rotate(180deg);
			position: relative;
			top: 2px;
			left: $padding-rythm-sm/2;
			display: none;

			&.is-active {
				display: inline-block;
			}
		}
	}

	&--content {
		opacity: 0;
		pointer-events: none;
		max-height: 0px;
		transition: max-height 1s ease-out, opacity 0.5s ease-in;

		&.is-visible {
			opacity: 1;
			height: auto;
			pointer-events: all;
			max-height: 15000px;
			pointer-events: all;
			transition: max-height 1s ease-out, opacity 0.2s ease-in;
			&.contact-card--grid {
				display: block;
				@supports(display: grid) {
					display: grid;
					grid-template-columns: 1fr;
				}
			}
		}
		&__full-height {
			height: 0;
			display: none;
			&.is-visible {
				display: block;
				height: 100vh;
				max-height: 100vh;
				transition: max-height 0.5s ease-out, opacity 0.5s step-start;
				overflow: scroll;
				z-index: $zi-layer-3;
			}
		}
	}

	&--title {
		display: inline-block;
		font-family: $condensed-font;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		letter-spacing: 0.05em;
	}
}

@include from($bp-medium) {
	.accordion {
		position: relative;

		&--opener {
			&__dark {
				max-width: none;
				display: none;
			}
			&__search {
				display: none;
				&.is-open {
					display: none;
				}
			}
			&__sidebar {
				padding: $padding-rythm-sm/2 $padding-rythm-sm/2 $padding-rythm-sm/2
					$padding-rythm;

				&.is-open {
					padding: $padding-rythm-sm/2 $padding-rythm-sm/2 $padding-rythm-sm/2
						$padding-rythm;
				}
			}

			& > svg {
				float: none;
				margin: 2px 0 -2px 10px;
			}
		}

		&--content {
			transition: max-height 0.5s ease-out, opacity 0.5s ease-in;

			&.is-visible {
				transition: max-height 0.5s ease-out, opacity 0.2s ease-in;
				&.contact-card--grid {
					display: grid;
					grid-template-columns: 1fr 1fr;
				}
			}

			&__full-height {
				display: block;
				opacity: 1;
				height: 100%;
				pointer-events: all;
				max-height: 100%;
			}
		}

		&--icon {
			&__left {
				position: absolute;
				left: 0;
				top: $padding-rythm-sm/2;
				display: inline-block;
			}
		}
	}
}

@include from($bp-large) {
	.accordion {
		&--opener {
			width: 100%;
			display: inline-block;

			&__dark {
				max-width: none;
				display: none;
			}

			&__search {
				display: none;
				&.is-open {
					display: none;
				}
			}
		}
	}
}

//for printing only - styles in print.css are overridden by this stylesheet
@media print {
	.accordion {
		&--content {
			opacity: 1 !important;
			max-height: 3000px !important;
		}
		&--opener {
			&__dark {
				background: none;
				color: black;
				border: none;
				display: none !important;
			}
			&__no-print {
				display: none;
			}
		}
	}
}
