.content-card {
	box-shadow: $shadow;
	padding: $padding-rythm-sm;
	border-radius: 2px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;

	&--title {
		color: $red;
		display: inline-block;
		font-family: $condensed-font;
		font-weight: 800;
		font-size: $fs-h2-sm;
		margin: 0 5px $padding-rythm-sm/2 0;

		& + span {
			color: $red;
		}
	}

	&--link {
		border: none;
		border-radius: 2px;
		background-color: $blue;
		border: 1px solid $blue;
		color: white;
		width: 100%;
		padding: 10px;
		text-align: center;
		align-self: flex-end;
		transition: color 0.2s ease, background-color 0.2s ease;

		& > svg {
			transform: rotate(90deg);
			margin-left: $padding-rythm-sm;
			height: 10px;
		}

		&:hover,
		&:focus,
		&:active {
			background-color: white;
			color: $blue;
			transition: color 0.2s ease, background-color 0.2s ease;
		}
	}

	&--overview {
		color: $blue;
		margin: 0 0 $padding-rythm-sm*2 0;
	}
}

@include from($bp-medium) {
	.l-committee {
		&--title {
		}

		&--member-cards {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}

@media print {
	.committee-card {
		&--link {
			display: none;
		}
	}
}
