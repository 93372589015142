body {
	font-size: $fs-body;
	font-family: $font;
	line-height: 1.4;
	display: flex;
	flex-flow: column wrap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include base-heading;
}

h1 {
	font-size: $fs-h1-sm;
}

h2 {
	font-size: $fs-h2-sm;
}

h3 {
	font-size: $fs-h3-sm;
}

h4 {
	font-size: $fs-body-sm;
}

h5 {
}

.lede {
	font-size: 1.2em;
}

.richtext,
.rich-text {
	color: $blue;

	> * + * {
		margin-top: 0.4em;
	}
	> * + h1,
	> * + h2,
	> p + p {
		margin-top: 1em;
	}
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}

	a:not([class]) {
		@include base-link;
		display: inline;

		border-bottom: 1px solid $blue;
		&:hover,
		&:focus,
		&:active {
			color: $red;
			border-bottom-color: $red;
		}
	}

	h1 {
		text-transform: uppercase;
		font-family: $condensed-font;
		color: $dark-grey;
	}
	h2 {
		font-family: $condensed-font;
		color: $red;
	}
	h3 {
		font-family: $condensed-font;
	}
	h4 {
		font-family: $condensed-font;
		font-weight: bold;
	}

	ul,
	ol {
		padding-left: 1em;
		margin: $padding-rythm-sm 0;
	}

	ul {
		li {
			margin: $padding-rythm-sm/2 $padding-rythm-sm;
			&:before {
				display: block;
				float: left;
				border-radius: 2px;
				background-color: $blue;
				width: 10px;
				height: 10px;
				margin-top: 4px;
				content: "";
				margin-left: -$padding-rythm-sm*1.5;
			}
			&:first-child {
				margin-top: $padding-rythm-sm/2;
			}
		}
	}

	ol {
		counter-reset: item;
		li {
			margin: $padding-rythm-sm/2 $padding-rythm-sm;
			counter-increment: item;
			list-style-type: none;

			&:before {
				display: block;
				float: left;
				display: inline-block;
				font-weight: bold;
				content: counter(item) ".";
				margin-left: -$padding-rythm-sm*1.5;
			}
		}
	}

	blockquote {
		font-style: italic;
		font-size: $fs-body;
		margin: $padding-rythm-sm/2;
	}

	.responsive-object {
		position: relative;
		iframe,
		img {
			position: absolute;
			height: 100%;
			width: 100%;
		}
	}
}

.blockquote {
	font-style: italic;
	font-size: 16px;
	margin: $padding-rythm-sm/2;
	color: $blue;
}

@include from($bp-medium) {
	body {
		flex-flow: row nowrap;
	}
	h1 {
		font-size: $fs-h1;
	}
	h2 {
		font-size: $fs-h2;
	}
	h3 {
		font-size: $fs-h3;
	}
	h4 {
		font-size: $fs-h3-sm;
	}

	.richtext,
	.rich-text {
		> * + * {
			margin-top: 1em;
		}
		*:first-child {
			margin-top: 0;
		}
		*:last-child {
			margin-bottom: 0;
		}

		blockquote {
			font-size: 16px;
			margin: $padding-rythm-sm;
		}
	}
}
