.letter-nav {
	width: 15%;
	padding: $padding-rythm $padding-rythm-sm/1.5;
	right: 0;

	&--letter {
		display: inline-block;
		text-transform: uppercase;
		color: $dark-grey;
		text-align: center;
		width: 100%;
		cursor: pointer;
		margin: 5px 0;
		font-weight: 300;
		transition: font-weight 0.3s ease;
		font-size: 10px;
		font-family: $light-font;
		height: 14px;
		&.is-active {
			font-family: $condensed-font;
			transition: font 0.3s ease;
			font-size: $fs-body;
		}
	}
}

@include from($bp-small) {
	.letter-nav {
		display: inline-block;
		position: fixed;
		right: $padding-rythm-sm;
		top: 130px;
		padding: 0;
		width: 5%;

		&--letter {
			display: inline-block;
			text-transform: uppercase;
			color: $dark-grey;
			text-align: center;
			width: 100%;
			cursor: pointer;
			margin: 5px 0;
			font-weight: 300;
			transition: font-weight 0.3s ease;
			font-size: $fs-body;
			height: 20px;

			&.is-active {
				font-weight: bold;
				transition: font-weight 0.3s ease;
				font-size: $fs-h3;
			}

			&:hover,
			&:focus {
				color: $middle-grey;
			}
		}
	}
}
